@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    /* @apply font-noto !important; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
  }

@layer utilities {
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      line-height: 1;
      transform: translateY(60px);
    }
    100% {
      opacity: 1;
      line-height: 1.4;
      transform: translateY(0);
    }
  }
  
  @keyframes catch_main_sp {
    0% { line-height: 1; }
    100% { line-height: 1.4; }
  }

  .fade-in-section {
    opacity: 0;
    transform: translateY(60px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .animate-fade-in-up {
    animation: fadeInUp 0.5s ease forwards;
  }
  
  .animate-catch {
    animation: catch_main_sp 0.1s 0.1s both;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  .animate-marquee-start {
    animation: marquee 60s linear infinite;
  
  }
  .text-9xl {
    font-size: 116px;
  }

  .fixed-full-screen {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }

  .loading-screen {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flow-item {
    position: relative;
}

.flow-item-line {
    position: absolute;
    top: 2.125rem;
    left: 4px;
    width: 2px;
    background-color: #eab308;
    height: 0;
    transform: translate(2.675rem, 0);
    transition: height 1s ease-out;
    z-index: 20;
}

.flow-item.active .flow-item-line {
    height: calc(100% + 1.275rem); /* 高さを元のデザインに合わせて調整 */
}

@media (min-width: 768px) {
    .flow-item-line {
        top: 2.9rem;
        height: 0; /* 初期状態では高さ0 */
    }

    .flow-item.active .flow-item-line {
        height: calc(100% + 1.4rem); /* アクティブ時の高さ */
    }
}
}

.image-container img {
  width: 60vw;
  height: 60vw;
  object-fit: contain;
}

@media (min-width: 640px) {
  .image-container img {
    width: 400px;
    height: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.menu-item {
  position: relative;
  padding-bottom: 6px;
}

.menu-item:not(.active)::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #eab308;
  transition: width 0.3s ease, left 0.3s ease;
}

.menu-item:hover::after {
  width: 100%;
  left: 0;
}